import React, { useContext, useState } from "react";

import { ContactFormContext } from "../context/contactForm.context";

const ContactForm = () => {
  const [isMenuClosing, setIsMenuClosing] = useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);

  const { setShowContactUsModal, showContactUsModal } =
    useContext(ContactFormContext);

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phonenumber: "",
    subject: "",
    description: "",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    const errors = {};

    if (!formData.fullname) {
      errors.fullname = "Full Name is required";
    }

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
    }

    if (!formData.phonenumber.trim()) {
      errors.phonenumber = "Phone Number is required";
    } else if (!/^\d+$/.test(formData.phonenumber)) {
      errors.phonenumber = "Phone Number is invalid";
    }

    if (!formData.subject.trim()) {
      errors.subject = "Subject is required";
    }

    if (!formData.description.trim()) {
      errors.description = "Description is required";
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (isSubmitClicked) {
      const validationErrors = validate();
      setErrors(validationErrors);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitClicked(true);

    const validationErrors = validate();

    if (Object.keys(validationErrors).length === 0) {
      // Submit form
      // alert("Form submitted successfully", formData);
      setErrors({});
      setFormData({
        fullname: "",
        email: "",
        phonenumber: "",
        subject: "",
        description: "",
      });

      setIsSubmitClicked(false);
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <>
      {showContactUsModal && (
        <>
          <button
            onClick={() => {
              setIsMenuClosing(true);
              setTimeout(() => {
                setShowContactUsModal(false);
              }, 900);
            }}
            className={`pointer-events-auto fixed top-10 lg:top-20 right-10   w-10 h-10 sm:w-10 sm:h-10  lg:w-12 lg:h-12 border-2 border-solid border-white uppercase text-center text-lg leading-[1.2] text-white rounded-full flex items-center justify-center active:scale-100 hover:shadow-[0px_0px_20px_#ffffff00,_0px_0px_20px_#ffffff75] transition-all  z-20 ${
              showContactUsModal && "blur-animate"
            } ${isMenuClosing && "fadeout-animate--1"}`}
          >
            <span className="w-5 sm:w-6 lg:w-7 h-0.5 bg-white inline-block absolute -rotate-45" />
            <span className="w-5 sm:w-6 lg:w-7 h-0.5 bg-white inline-block absolute rotate-45" />
          </button>

          <section
            className={`main_menu absolute w-full z-10 top-0 h-[100dvh] bg-[#53535378] backdrop-blur-[40px] backdrop-saturate-[180%]
    ${showContactUsModal && "blur-animate"} ${
              isMenuClosing && "fadeout-animate--1"
            }`}
          >
            <div className="relative flex items-center justify-center w-full h-full">
              <form
                className="max-w-sm mx-auto min-w-[500px] p-8 border border-white/40 rounded-xl"
                onSubmit={handleSubmit}
              >
                <h2 className="text-white text-2xl font-semibold px-2 mb-5">
                  Please share your queries with us!
                </h2>
                <div className="flex flex-wrap gap-y-4">
                  <div className="w-1/2 px-2">
                    <label
                      for="fullname"
                      className="block mb-2 text-base text-white font-medium"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      name="fullname"
                      value={formData.fullname}
                      onChange={handleChange}
                      className="placeholder:text-white/65 border bg-transparent border-white/40 text-white focus:outline-0 text-sm rounded-lg block w-full p-2.5 "
                      placeholder="Enter your full name"
                      //required
                    />
                    <p className="text-sm mt-1 text-red-600">
                      {errors.fullname}
                    </p>
                  </div>
                  <div className="w-1/2 px-2">
                    <label
                      for="email"
                      className="block mb-2 text-base text-white font-medium"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="border placeholder:text-white/65 bg-transparent border-white/40 text-white focus:outline-0 text-sm rounded-lg block w-full p-2.5 "
                      placeholder="Enter your Email"
                      //required
                    />
                    <p className="text-sm mt-1 text-red-600">{errors.email}</p>
                  </div>
                  <div className="w-1/2 px-2">
                    <label
                      for="phonenumber"
                      className="block mb-2 text-base text-white font-medium"
                    >
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      name="phonenumber"
                      value={formData.phonenumber}
                      onChange={handleChange}
                      className="border placeholder:text-white/65 bg-transparent border-white/40 text-white focus:outline-0 text-sm rounded-lg block w-full p-2.5 "
                      placeholder="Enter your phone number"
                      //required
                    />
                    <p className="text-sm mt-1 text-red-600">
                      {errors.phonenumber}
                    </p>
                  </div>
                  <div className="w-1/2 px-2">
                    <label
                      for="subject"
                      className="block mb-2 text-base text-white font-medium"
                    >
                      Subject
                    </label>
                    <input
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      className="border placeholder:text-white/65 bg-transparent border-white/40 text-white focus:outline-0 text-sm rounded-lg block w-full p-2.5 "
                      placeholder="Enter your subject"
                      //required
                    />
                    <p className="text-sm mt-1 text-red-600">
                      {errors.subject}
                    </p>
                  </div>
                  <div className="w-full px-2">
                    <label
                      for="description"
                      className="block mb-2 text-base text-white font-medium"
                    >
                      Description
                    </label>
                    <textarea
                      name="description"
                      rows="4"
                      value={formData.description}
                      onChange={handleChange}
                      className="border placeholder:text-white/65 bg-transparent border-white/40 text-white focus:outline-0 text-sm rounded-lg block w-full p-2.5"
                      placeholder="Write your thoughts here..."
                    ></textarea>
                    <p className="text-sm mt-1 text-red-600">
                      {errors.description}
                    </p>
                  </div>
                  <div className="px-2 w-full mt-4">
                    <button
                      type="submit"
                      className="block border border-white bg-white rounded-md text-blue  px-8 py-2.5 font-semibold text-base"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ContactForm;
